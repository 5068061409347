import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../components/layout';
import Menu from '../../components/menuComponent';
import WorkWrapper from '../../components/workWrapper';

const WorkContainer = ({ location }) => {
  const { pathname } = location;
  const filter = pathname.split('/').filter((pathString) => pathString.length > 0).pop();

  return (
    <Layout
      title="Portfolio Showcase | Wow Interior Design"
      description="Browse through examples from our extensive catalogue of installations completed over the years & brochure images. We believe it shows the diverse range of fitted furniture & bespoke storage solutions we offer."
    >
      <Menu />
      <WorkWrapper
        filter={filter}
      />
    </Layout>
  );
};

export default WorkContainer;

WorkContainer.propTypes = {
  location: PropTypes.object.isRequired,
};
